<template>
          <v-overlay
            contained
            class="align-center justify-center"
          >
            <v-card class="discipline-card"
                height="90vh" width="90vw"
                contain
                padding = "10px"
                >

                <v-row class="justify-center">
                    <v-spacer> </v-spacer>
                    <v-icon>{{disciplineCards[this.id].iconName}} </v-icon>
                    <v-card-title class="white--text">
                            {{disciplineCards[this.id].title}}
                    </v-card-title>
                    <v-spacer> </v-spacer>
                    <v-btn color="#A7121D" dark text
                        @click="closeOverlay()"
                        >
                        Close 
                    </v-btn>
                </v-row >
                <v-row class="grey--text">
                    <v-col cols="6">
                        <div>
                            {{disciplineCards[this.id].cardDescription[0]}}
                        </div>
                        <v-img max-height="50vh" :src="disciplineCards[this.id].pictures[0]" >
                        </v-img>
                    </v-col>
                    <v-col cols="6">
                        <v-img  max-height="50vh" :src="disciplineCards[this.id].pictures[1]" >
                        </v-img>
                        {{disciplineCards[this.id].cardDescription[1]}}
                    </v-col>
                </v-row>
                <v-row class="align-center justify-center">
                    <div v-for="soft in disciplineCards[this.id].softwares" :key="soft.name">
                        <v-spacer></v-spacer>
                        <v-img height="30px" width="30px" :src="soft.iconLink"> </v-img>
                        <!-- <v-card-text>{{ soft.name }}</v-card-text> -->
                    </div>
                </v-row>
                
            </v-card>
            
          </v-overlay>
        
  </template>

<script>
  export default {
    props: {
        id : {Int16Array},
    },
    methods: {
        closeOverlay() {
            this.$emit("update:show", false)
        }
    }, 
    data: () => ({
        show: {Boolean, required: true},
        disciplineCards: [
        { title: 'Illustration', iconName:"mdi-palette", 
        cardDescription:[
            "I have always love illustration as a way to tell stories. I am passionate about drawing characters with a background and to design universes.",
            "I started to draw and paint traditionally and discovered digital painting in 2016. I have a balance between these two medium and I love doing both. "
        ],
        pictures: [
            "Disc-illu-00.png", "Disc-illu-01.png"
        ],
        softwares: [
            {"name": "photoshop", "iconLink": "/icons/icons8-photoshop-24.ico"},
            {"name": "procreate", "iconLink": "/icons/procreate-icon.png"}
        ] 
        },
        { title: 'Audiovisual', iconName:'mdi-movie',
        cardDescription:[
            "I started to make movies at the IMAC School in France. I did two short movies with class and one in a 48 hours challenge. I loved working on team on these projects and learning new skills. It was my first entrance to editing.",
            "Since 2018, I also realize short personal movies, I edit my videos every week and with time I started to gain in skills and I can now edit, color and manipulate the sound."
        ],   
        pictures: [
            "Disc-edit-00.gif", "Disc-edit-01.gif",
        ],     
        softwares: [
            {"name" : "premiere pro", "iconLink": "/icons/icons8-adobe-premiere-pro-24.png"},
            {"name" : "after effects", "iconLink": "/icons/icons8-adobe-after-effects-30.png"}
        ] 
        },
        { title: 'Programming', iconName:'mdi-monitor', 
        cardDescription:[
            "I started to program games in 2017 and web in 2020 with school. I made several games and a few websites like this one.",
            "I can code in python, C, C++, Java, and also HTML, CSS et JS."
        ],  
        pictures: [
            "Disc-prog-00.png", "Disc-prog-01.png",
        ], 
        softwares: [
            {"name": "vue.js", "iconLink": "/icons/icons8-vue-js-48.png"},
            {"name": "vuetify", "iconLink": "/icons/icons8-vuetify-32.png"},
            {"name": "unity", "iconLink": "/icons/icons8-unity-50.png"},
        ] 
        },
        { title: 'Animation', cardClass:'child bgColor2', cardColor:'', iconName:'mdi-fast-forward',iconColor:'white', 
        cardDescription:[
            "My two main passions are illustration and cinema, animation is therefore a medium that suits me perfectly. I had an animation project in 2021 where I animated a forest with after effect and blender.",
            "I started to have class about animation in 2023 in Weimar (Bauhaus Universität). I discovered several techniques, from stop motion with DragonFrame to digital animation with TVPaint."
        ],
        pictures: [
            "Disc-anim-00.gif", "Disc-anim-01.gif"
        ], 
        softwares: [
            {"name": "procreate", "iconLink": "/icons/procreate-icon.png"},
            {"name" : "after effects", "iconLink": "/icons/icons8-adobe-after-effects-30.png"},
            {"name": "blender", "iconLink": "/icons/icons8-blender-50.png"},
            {"name": "procreate Dreams", "iconLink": "/icons/icon-procreate-dreams.png"}

        ]        
        },
      ]
    })
  }
</script>

<style scoped>
.discipline-card {
    padding: 25px;
}
</style>