<template>
  <v-app 
    id="app"
    :style="{ background: $vuetify.theme.themes.dark.background }"
  >
  <NavBarHome v-if="this.display=='homepage' || this.display=='cursus'" @updateDisplay="updateDisplay"/>
  <NavBarGallery v-if="this.display=='gallery'" @updateDisplay="updateDisplay"/>
  <NavBarProject v-if="this.display=='project'" @updateDisplay="updateDisplay"/>

  <v-main>
      
      <HomePage v-if="this.display=='homepage'" @updateDisplay="updateDisplay" @updateProjectId="updateProjectId" />
      <GalleryPage v-if="this.display=='gallery'" @updateDisplay="updateDisplay" @updateProjectId="updateProjectId"/>
      <ProjectPage v-if="this.display=='project'" :detailedProjectId.sync="this.detailedProjectId" @updateDisplay="updateDisplay" />
      <CursusPage v-if="this.display=='cursus'" @updateDisplay="updateDisplay" />
    </v-main>
  <Footer />
  </v-app>
</template>

<script>
import NavBarHome from "./components/NavBar1.vue";
import NavBarGallery from "./components/NavBar2.vue"
import NavBarProject from "./components/NavBar3.vue";
import HomePage from './views/HomePage.vue';
import GalleryPage from "./views/GalleryPage.vue";
import ProjectPage from "./views/ProjectPage.vue";
import CursusPage from "./views/CursusPage.vue";
import Footer from "./components/FooterComponent.vue";

export default {
    name: 'App',
    data: () => ({
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"], 
      display: 'homepage',
      detailedProjectId: '0',
    }),
    methods: {
      updateDisplay(newdisplay) {
        window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
        this.display = newdisplay;
      },
      updateProjectId(newId) {
        this.detailedProjectId = newId;
      }
    },
    components: { 
      NavBarHome, NavBarGallery, NavBarProject,
      HomePage, 
      GalleryPage,
      ProjectPage,
      CursusPage,
      Footer, 
    }
};
</script>