
<template>
    <v-app
        id="project"
        :style="{ background: $vuetify.theme.themes.dark.background }"
    >
    <v-container fluid>
        <v-col cols="12">
            <h1 class="white--text">Featured Projects</h1>
            <SearchBar :search.sync="search"/> 
          </v-col>
        <v-row dense>
          <v-col cols="12">
          </v-col>
          <v-col
            class="card-project"
            v-for="card in projectsOrganizedList"
            :key="card.id"
            :cols="card.flex"
          >
            <v-card :style="{ background: $vuetify.theme.themes.dark.background }">               
              <v-img @click="projectId(card.id)" hover
                :src="card.cover"
                class="align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="30vh"

                cover
              >
                <v-card-title class="white--text tohide3" > {{ card.title }}
                </v-card-title>
            </v-img>
            
            <v-card-subtitle class="grey--text tohide2">
              <v-icon size="normal" color="#A7121D"> {{ card.iconName }}</v-icon>
              {{ card.category }} - {{ card.date }}</v-card-subtitle>
                <p class="grey--text tohide1">{{ card.description[0] }} - {{ card.description[1] }}</p>
            </v-card>
          </v-col>
        </v-row> 
    </v-container>


    </v-app>
</template>
<script>
import projectsList from '../../public/projectsList.json' ;
import SearchBar from '../components/SearchBarComponent.vue';

export default ({
    name: 'GalleryPage',
    data: () => ({ 
        jsonProjects: projectsList.projects,  
        search: localStorage.getItem("search")|| "",
        showDetailedCard: true,
        detailedCardId: '0',
    }),
    computed: {
      projectsOrganizedList: function () {
        let organizedList = this.jsonProjects
        const search = this.search.length > 0 ? true : false

        const containFunc = (a) => a.subtitle + a.title + a.category 
        const searchFunc = (a) => containFunc(a).toLowerCase().includes(this.search.toLowerCase())

        if (search)
          organizedList = organizedList.filter(searchFunc)
        return organizedList
       }
    },
    methods: {
      moreInformation (id) {
          console.log("Go Overlay "+id);
          this.showDetailedCard = true;
          this.detailedCardId = id;
          this.detailedProjectJson();
      },
      detailedProjectJson() {
        return this.jsonProjects[parseInt(this.detailedCardId)-1]
      }, 
      projectId (newId) {
          console.log("click on image "+newId)
          this.$emit('updateDisplay', 'project');
          localStorage.setItem('saveState', JSON.stringify({page:'gallery'}));
          this.$emit('updateProjectId', newId);
        },
    },
    components: {
      SearchBar,
    },
})
</script>
<style scoped>
.card-project {
  padding: 2vh;
}

@media only screen and (max-width: 820px) {
  .tohide1 {
    display: none;
  }
}
@media only screen and (max-width: 680px) {
  .tohide2 {
    display: none;
  }
}
@media only screen and (max-width: 550px) {
  .tohide3 {
    display: none;
  }
}
</style>