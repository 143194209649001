<template>
    <v-app-bar app color="#111111" dark flat class="px-8">
      <v-btn icon @click="goHome()">
        <v-img src="../assets/Signature_blanc.png" contain max-height="50px"></v-img>
      </v-btn>
  
      <v-spacer></v-spacer>
      <v-btn text @click="goHome()">Home</v-btn>
      <v-btn text @click="scroll('project')">Projects</v-btn>
      <v-btn text @click="scroll('contact')"> Contact</v-btn>
    </v-app-bar>
  </template>
  
  <script>
  export default {
    name: 'NavBarGallery',
    data: () => ({
    }),
    methods: {
      scroll(refName) {
          const element = document.getElementById(refName);
          element.scrollIntoView({ behavior: "smooth" });
      },
      goHome () {
        window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
        this.$emit("updateDisplay", 'homepage');
        localStorage.setItem('saveState', JSON.stringify({page: "homepage"}))
      }
    },
  };
  </script>
  
  <style>
  </style>
  