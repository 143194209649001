<template>
    <v-container fluid>
      <v-carousel hide-delimiter-background
          :cycle="false"
          :touch="true"
          @change="OnChange()"
          v-model="carouselItem"
          >
        <v-carousel-item 
          v-for="(item, pic) in pictures" 
          :key="pic" 
          @click="openModal(item)"
          position="50% 50%"
          
          >
          <v-card dark>
            <v-responsive  class="carousel-video" v-if="item.mediaType === 'video'"
            :class="{ 'fill-height': isHeightGreater(item.url), 'fill-width': isWidthGreater(item.url)}" 
            ma-0 
            >
              <video id="video1" class="modal-video" :src="item.url" controls width="100%" contain>
              Your browser does not support the video tag. </video>
            </v-responsive>
            <v-img class="carousel-photo" width="100%" contain
            v-else :src="item.src" ma-0
                  :class="{ 'fill-height': isHeightGreater(item.src), 'fill-width': isWidthGreater(item.src) }"
            ></v-img>
            <v-card-text> {{ item.desc }}</v-card-text>
          </v-card>
        </v-carousel-item>
      </v-carousel>

      <v-dialog v-model="dialog" max-width="600" >
        <v-carousel 
          v-model="carouselItem"
          :cycle="false" 
          :touch="true"
          @change="OnChange()">
          <v-carousel-item v-for="(item, pic) in pictures" :key="pic" position="50% 50%">
            <v-card dark >
              <v-responsive v-if="item.mediaType === 'video'">
                <video id="video2" class="modal-video" :src="item.url" contain width="100%" controls>
                Your browser does not support the video tag. </video>
              </v-responsive>
            <v-img v-else :src="item.src" contain class="modal-photo"></v-img>
            <v-card-text> {{ item.desc }}</v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
        
      </v-dialog>
    </v-container>
  </template>
  
  <script>

  export default {
    props: {
      pictures: {
            type: Object,
            required: true
      }, 
    },
    data() {
      return {   
        dialog: false,
        carouselItem: "pic1",
        videoPlayer: false,
      };
    },
    methods: {
      openModal(item) {
        this.dialog = true;
        this.carouselItem = item;
      }, 
      closeModal() {
        this.dialog = false;
      }, 
      isHeightGreater(src) {
        const img = new Image();
        img.src = src;
        return img.height > img.width;
      },
      isWidthGreater(src) {
        const img = new Image();
        img.src = src;
        return img.width > img.height;
      }, 
      OnChange () {
        var video1 = document.getElementById("video1")
        if (video1){
          video1.pause();
        }
        var video2 = document.getElementById("video2")
        if (video2){
          video2.pause();
        }
      }
    }
  };

  </script>
  
  <style scoped>
  .carousel-photo {
    max-height: 70vh;
    max-width: 70vw;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .carousel-video {
    max-height: 90vh;
    max-width: 70vw;
  }
  .modal-photo {
    max-height: 70vh;
    max-width: 70vw;
  }

  .modal-video {
    max-height: 90vh;
    max-width: 90vw;
  }
  </style>
  