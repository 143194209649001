<template>
    <v-container>
        <v-row>
        <v-col >
          <v-text-field
            label="Search for a project or a type of project"
            class="white--text" 
            background-color="grey"
            solo-inverted
            append-icon="mdi-magnify"
            :value="search" 
            @input="updateSearch" 
          >
        </v-text-field>

        </v-col>
        <v-col solo-inverted>
            <v-btn  v-if="search" @click="cleanSearch" icon >
                  <v-icon color="#A7121D" > mdi-close-box </v-icon>
            </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

export default ({
    name: 'SearchBar',
    props: {
        search: {type: String, required: true}
    }, 
    watch: {
        search: function(newSearch) {
            localStorage.setItem("search", newSearch)
        }
    },
    methods: {
        cleanSearch: function(){
            this.$emit("update:search", "")
        },
        updateSearch: function(value) {
            console.log (value)
            this.$emit("update:search", value)
        }
    }
})
</script>


<style>
v-text-field {
    width: fit-content;
}
</style>
