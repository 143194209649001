<template>
    <v-app
      id="home"
      :style="{ background: $vuetify.theme.themes.dark.background }"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="5">
            <v-img src="animation.gif" contain max-height="250"></v-img>
          </v-col>
          <v-col cols="7">
            <h5 class="red--text text--darken-4 mt-10">Hello I'm Margot</h5>
            <h1 class="white--text">Visual Artist</h1>
            <p class="grey--text">
              I am passionate about illustration and every visual art form. <br />
              I mostly work on animations, illustrations, short movies and video games.<br />
              I am following an engineer formation at the IMAC School in France and will soon graduate. 
            </p>
            <v-row text-align="left">
              <v-btn color="#A7121D" dark text @click="updateDisplay('cursus')"> More about my cursus </v-btn>
            </v-row>
          
          </v-col>
                  
          <v-col cols="12" class="padd">
            <div class="first" id="project">
              <v-row>
                <v-col 
                  v-for="card in disciplineCards"
                  :key="card.title"
                >
                  <v-card :class="card.cardClass" height="fit-content">
                    <v-icon :color='card.iconColor' x-large class="ml-3" v-text="card.iconName">
                     </v-icon>
                    <v-card-title class="white--text ml-0 mt-4" v-text="card.title">
                    </v-card-title>
                    <v-card-text class="grey--text ml-3" v-text="card.cardDescription">
                    </v-card-text>
                    <v-card-actions>
                      <v-btn :color="card.iconColor" dark text @click="openOverlay(card.id)">
                        know more
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                    
                  </v-card>
                </v-col>
                <disciplineOverlay v-if="showDetailedDiscipline" :show.sync="showDetailedDiscipline" :id.sync="showDisciplineId"/>

                <!-- <v-col cols="12" class="mt-10">
                
                  <div class="child1">
                    <h1 class="red--text text--darken-4 mt-4 number">12</h1>
                    <h3 class="white--text mt-4">Years Experience</h3>
                  </div>
                
                  <div class="child2 mRight">
                    <v-row>
                      <v-col cols="12" class="childcol">
                        <div class="child2 mButton padding bgColor1">
                          <h1 class="red--text text--darken-4">60+</h1>
                          <p class="grey--text">Clients</p>
                        </div>
                      </v-col>
                
                      <v-col cols="12" class="childcol">
                        <div class="child2 padding bgColor1">
                          <h1 class="red--text text--darken-4">122+</h1>
                          <p class="grey--text">Completed Projects</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                
                  <div class="child2">
                    <v-row>
                      <v-col cols="12" class="childcol">
                        <div class="child2 mButton padding bgColor1">
                          <h1 class="red--text text--darken-4">08</h1>
                          <p class="grey--text">Years Experience</p>
                        </div>
                      </v-col>
                
                      <v-col cols="12" class="childcol">
                        <div class="child2 padding bgColor1">
                          <h1 class="red--text text--darken-4">10</h1>
                          <p class="grey--text">Achievements</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col> -->
              </v-row>
            </div>
          </v-col>
          
          <v-col
            cols="12"   
            class="padd"
          >
          <shortGallery @updateProjectId="updateProjectId" @updateDisplay="updateDisplay"/>          
        </v-col>
          
          <v-col cols="12" class="padd topInverse" >
            <div class="second">
              <div class="secondchild1" id="portfolio">
                <v-row>
                    <h1 class="white--text ml-8 mr-8 " >
                      You can download my portfolio <br/>and my resume here
                    </h1>
                </v-row>
                    
                
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn tile color="#A7121D" dark class="mt-8" download href="Portfolio(eng).pdf"
                    >
                      portfolio
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile color="#A7121D" dark class="mt-8" download href="CV2023.pdf"
                    >
                      resume
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-row>
              </div>
            </div>
          </v-col>
          
        </v-row>
      </v-container>
    </v-app>
  </template>
  
  <script>
  import shortGallery from "../components/GalleryComponent.vue"
import disciplineOverlay from "@/components/DisciplineOverlay.vue";
  
export default {
    name: "HomePage",
    props: {
      
    },
    data: () => ({
      projectNb: {type: Int16Array, required: true},
      disciplineCards: [
        { id:"0", title: 'Illustration', cardClass:'child bgColor1', cardColor:'', iconName:"mdi-palette",iconColor:'#A7121D', 
        cardDescription:"Illustration is my main focus, I draw since my first years and I'd like to make it my main field of work. My dream would be to illustrate a children book."},
        { id:"1", title: 'Audiovisual', cardClass:'child bgColor2', cardColor:'', iconName:'mdi-movie',iconColor:'white', 
        cardDescription:"I did several movies and photography projects and I think it is a great way for me to tell my stories and bring my ideas to life, while using my technical background."},
        { id:"2", title: 'Programming', cardClass:'child bgColor1', cardColor:'', iconName:'mdi-monitor',iconColor:'#A7121D', 
        cardDescription:'I code games and websites since 2017, first in class, later for personal projects like this website. I find in games a great field of creativity.'},
        { id:"3", title: 'Animation', cardClass:'child bgColor2', cardColor:'', iconName:'mdi-fast-forward',iconColor:'white', 
        cardDescription:"I combine two of my passions, illustration and videos, since 2022 by learning animation, and I am truly passionate about this medium of expression."},
      ],
      showDetailedDiscipline: false,
      showDisciplineId: 0,
    }),
    components: {
      shortGallery,
      disciplineOverlay
    },
    methods: {
      updateDisplay(display) {
        this.$emit('updateDisplay', display);
      },
      updateProjectId(newId) {
        this.$emit('updateProjectId', newId);
      }, 
      openOverlay(idDiscipline) {
        this.showDetailedDiscipline = true;
        this.showDisciplineId = parseInt(idDiscipline);
        console.log("open Overlay " + this.showDetailedDiscipline + " " + this.showDisciplineId)
      }
    },
  };
  </script>
  <style scoped>
  .top {
    margin-top: 180px;
  }
  .topInverse {
    margin-top: -250px;
  }
  .topTolbar {
    margin-top: 100px;
    text-align: center;
  }
  .first {
    width: 100%;
    background-image: url("../../public/background.jpeg");
    background-repeat: no-repeat;
    background-size: auto 60vh;
    text-align: center;
    padding: 2rem 2rem;
  }
  .second {
    width: 100%;
    margin-top: 10vh;
    background: #3d2a2c;
    text-align: center;
    padding: 2rem 2rem;
  }
  .secondchild1 {
    font-size : medium;
    display: inline-block;
    background-color: #1e1e1e;
    padding: 2rem 1rem;
    vertical-align: middle;
    text-align: center;
    margin-top: 150px;
  }
  .child {
    display: inline-block;
    padding: 2rem 1rem;
    vertical-align: middle;
    text-align: left;
    margin-right: 8px;
  }
  .bgColor1 {
    background-color: #1e1e1e;
  }
  .bgColor2 {
    background-color: #3d2a2c;
  }
  
  .child1 {
    display: inline-block;
    padding: 2rem 1rem;
    vertical-align: middle;
    margin-right: 5px;
    width: 240px;
  }
  .child2 {
    display: inline-block;
    width: 245px;
    vertical-align: middle;
  }
  .mRight {
    margin-right: 8px;
  }
  .mButton {
    margin-bottom: 8px;
  }
  .padding {
    padding: 8px 0;
  }
  
  .col-12.padd {
    padding: 12px 0 !important;
  }
  .col-12.childcol {
    padding: 0 !important;
  }
  h1.number {
    font-size: 50px;
    font-weight: bold;
  }
  </style>