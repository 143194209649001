<template>
  <v-container fluid>
    <v-row>
            <v-col md="3" offset-md="3" id="project"
            >
            <h4 class="white--text">FEATURED PROJECTS</h4>
            <p class="grey--text">You can find an overview of my projects here</p>
          </v-col>
          <v-col md="3" class="text-end">
            <v-btn tile color="#A7121D" dark @click="toggleDisplay"> View All </v-btn>
          </v-col>
          <v-col 
          v-for="project in projectsToDisplay" :key="project.id"
          :md="project.md" :offset-md="project.offset">
            <v-card
              class="pa-2"
              outlined
              tile
              hover
              height="fit-content"
              color="#1E1E1E"
              @click="projectId(project.id)"
            >
              <v-icon color="#A7121D"> {{ project.iconName }}</v-icon>
              <v-card-title class="white--text" > {{ project.title }}</v-card-title>
              <v-card-subtitle class="grey--text" > {{ project.category }}</v-card-subtitle>
              <v-img 
                :src="project.cover" 
                contain 
                max-height="30vh"
                >
              </v-img>
              <p class="grey--text pt-5 text-center" > {{ project.description[1] }}</p>
              <v-row class="icons-display" >
                <div v-for="technic in project.technics" :key="technic.name">
                  <v-img  class="custom-icon" :src="technic.iconLink"/> 
                  <v-spacer></v-spacer>
                </div>
                
              </v-row>
              
            </v-card>
          </v-col>
        </v-row>

  </v-container>
       
</template>

<script>
import projectsList from '../../public/projectsList.json' ;

export default ({
    name: "shortGallery",
    props: {
    },
    data: () => ({ 
        jsonProjects: projectsList.projects,   
        nbOfProjectsToDisplay: 4, 
        showDetailedProject: true,   
    }),
    computed: { 
      projectsToDisplay() {
          return this.jsonProjects.slice(0, this.nbOfProjectsToDisplay);
      },
    },
    methods: {
        toggleDisplay() {
          this.$emit('updateDisplay', 'gallery');
          localStorage.setItem('saveState', JSON.stringify({page:'gallery'})) 
        },
        projectId (newId) {
          this.$emit('updateDisplay', 'project');
          localStorage.setItem('saveState', JSON.stringify({page:'homepage'}));
          this.$emit('updateProjectId', newId);
        },
        moreInformation () {
            // this.showDetailedProject = true;
            // this.detailedProjectId = id;
            // console.log("Go Overlay "+this.detailedProjectId+" show "+this.showDetailedProject);
            // this.$emit('updateDisplay', 'project');
            return this.detailedProjectJson();
        },
        detailedProjectJson() {
          // return this.jsonProjects[parseInt(this.detailedProjectId)-1]
        }
    },
    component: {
    }
})
</script>

<style scoped>
.custom-icon {
  height: 5vh;
  width: 5vh;
  margin: 2vh
}
.icons-display {
  justify-content: center;
}
</style>