<template>
    <v-app
      id="project"
      :style="{ background: $vuetify.theme.themes.dark.background }"
    >
      <v-container fluid>
        <v-card class="project-detail-card theme--dark grey--text py-5 px-5 text-center"
                width="100%"
        >
          <v-col>
            <v-row>
              <v-icon xLarge color="#A7121D" > {{storeProjectToDisplay.iconName}}</v-icon>

              <v-col>
                <v-card-title class="pt-1"> {{storeProjectToDisplay.title}} </v-card-title> 
                <v-card-subtitle class="text-start" > {{ storeProjectToDisplay.category }}</v-card-subtitle>   
              </v-col>
              <v-col>
                <v-card-text class="pt-1">
                    {{storeProjectToDisplay.description [0]}}
                </v-card-text>
              </v-col>
              <v-col md="3" class="text-end">
                <v-btn tile color="#A7121D" dark @click="quitComponent()">  Close 
                    <v-icon right>mdi-close-box</v-icon>
                </v-btn>
                <p class="pt-2">{{storeProjectToDisplay.date}}</p>
              </v-col>
            </v-row> 
          </v-col>
          <v-row>
            <v-card-text class="pt-1"></v-card-text>
            <v-col cols="12" sm="5">
              
              <v-card-text class="pt-1">
                  {{storeProjectToDisplay.description [1]}}
              </v-card-text>
              <v-img class="picture-details" :src="storeProjectToDisplay.cover"/> 
              <v-card-text class="pt-1">
                  {{storeProjectToDisplay.description [2]}}
              </v-card-text>
              <v-card-text class="pt-2">
                <v-btn dark text v-if="storeProjectToDisplay.link === 'true'" target="_blank" :href="storeProjectToDisplay.links"> {{storeProjectToDisplay.linkName}} </v-btn>
                <v-card-text class="pt-2">
                  Softwares used to create this project
                </v-card-text>
                <v-row class="icons-display">
                  <div v-for="technic in storeProjectToDisplay.technics" :key="technic.name">
                    <v-img color="#A7121D" class="custom-icon" :src="technic.iconLink"/>
                  <v-spacer></v-spacer>
                  </div>
                </v-row>
              </v-card-text> 
            </v-col>
            <v-col cols="12" sm="7">
              <v-card-text class="pt-1">
                  {{storeProjectToDisplay.description [3]}}
              </v-card-text>
              <v-card-text class="pt-2">
                  Discover a few images of the project :
                <CarrouselComponent :pictures="storeProjectToDisplay.pictures"/>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6">
              
            </v-col>
          </v-row>
        </v-card>

        
          
      </v-container>
    </v-app>
  </template>
  
  <script>
import projectsList from '../../public/projectsList.json' ;
import CarrouselComponent from '@/components/CarrouselComponent.vue';
  
export default {
    name: "ProjectPage",
    components: {
      CarrouselComponent
    },
    props: {
      detailedProjectId: {type: String},
    },
    data: () => ({
      jsonProjects: projectsList.projects,
      project: {type: Object}
    }),
    computed: {
      storeProjectToDisplay() {
        return this.jsonProjects[parseInt(this.detailedProjectId)-1]
      }
    },
    methods: {
      updateDisplay() {
        this.$emit('updateDisplay', 'homepage');
        localStorage.setItem('saveState', JSON.stringify({page:'homepage'}))
      },
      quitComponent() {
        console.log("retour à la page précédente");
        const stateString = localStorage.getItem('saveState')
        if (stateString){
          const state = JSON.parse(stateString)
          console.log (state.page)
          this.$emit('updateDisplay', state.page)
        }
        // this.updateDisplay()
      }
      
    }
  };
  </script>
  <style scoped>
  .picture-details {
    max-width: 60vw;
    /* max-height: 40vh; */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    }

  .icons-display {
    justify-content: center;
  }

  .custom-icon {
    height: 6vh;
    width: 6vh;
    margin: 2vh;
  }
  .top {
    margin-top: 180px;
  }
  .topInverse {
    margin-top: -250px;
  }
  .topTolbar {
    margin-top: 100px;
    text-align: center;
  }
  .first {
    width: 100%;
    background-image: url("../../public/background.jpeg");
    background-repeat: no-repeat;
    background-size: auto 60vh;
    text-align: center;
    padding: 2rem 2rem;
  }
  .second {
    width: 100%;
    margin-top: 10vh;
    background: #3d2a2c;
    text-align: center;
    padding: 2rem 2rem;
  }
  .secondchild1 {
    display: inline-block;
    background-color: #1e1e1e;
    padding: 2rem 1rem;
    vertical-align: middle;
    text-align: left;
    margin-top: 150px;
  }
  .child {
    display: inline-block;
    padding: 2rem 1rem;
    vertical-align: middle;
    text-align: left;
    margin-right: 8px;
  }
  .bgColor1 {
    background-color: #1e1e1e;
  }
  .bgColor2 {
    background-color: #3d2a2c;
  }
  
  .child1 {
    display: inline-block;
    padding: 2rem 1rem;
    vertical-align: middle;
    margin-right: 5px;
    width: 240px;
  }
  .child2 {
    display: inline-block;
    width: 245px;
    vertical-align: middle;
  }
  .mRight {
    margin-right: 8px;
  }
  .mButton {
    margin-bottom: 8px;
  }
  .padding {
    padding: 8px 0;
  }
  
  .col-12.padd {
    padding: 12px 0 !important;
  }
  .col-12.childcol {
    padding: 0 !important;
  }
  h1.number {
    font-size: 50px;
    font-weight: bold;
  }
  </style>