<template>
    <v-app
      id="home"
      :style="{ background: $vuetify.theme.themes.dark.background }"
    >
      <v-container fluid class="container-cursus">
        <v-row>
          <v-col cols="5" >
            <v-img src="animation.gif" contain max-height="250"></v-img>
          </v-col>
          <v-col cols="7">
            <h5 class="red--text text--darken-4 mt-10">Hello I'm Margot</h5>
            <h1 class="white--text">Visual Artist</h1>
            <p class="grey--text">
              I am passionate about illustration and every visual art form. <br />
              I mostly work on animations, illustrations, short movies and video games.<br />
              I am following an engineer formation at the IMAC School in France and will soon graduate. 
            </p>
          </v-col>

          <v-col cols="7">
            <h5 class="red--text text--darken-4 mt-10">Experiences</h5>
            <h1 class="white--text">💻 Professional Experiences 💻</h1>


            <h3 class="grey--text">• Illustrator & Web designer • <a href="https://www.exlibris.paris/">Ex Libris Paris </a> •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
            <p class="grey--text">
                📆 6 months from february to september 2024<br/>
                Production of illustrations for the custom stamps, <br/>
                Design of the new website (Squarespace, Shopify), <br/>
                Internal organization (Notion, Zapier, SEO), <br/>
                Creation of Adds Meta (SEA), <br/>
                Sale at Le Bon Marché in Paris. 
            </p>


            <h3 class="grey--text">• Video Editor • <a href="https://www.mcclients.fr/">Mission Capital Client</a> for Alpes Consigne •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
           
            <p class="grey--text">
                📆 1 week in june 2024 <br/>
                Edition of a client testimony for Mission Capital Client, a 7 min video with animations.
            </p>


            <h3 class="grey--text">• Illustrator •<a href="https://www.mcclients.fr/">Mission Capital Client</a> for L'Opera Bastille •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">

            <p class="grey--text">
                📆 2 weeks in may 2024 <br/>
                Production of 27 illustrations to illustrate the targets of l'Opera Bastille.
            </p>


            <h3 class="grey--text">• Illustrator • <a href="https://www.unapei92.fr/">Unapei 92</a> •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
            <p class="grey--text">
                📆 3 months during the summer 2023 <br/>
                Production of 15 illustrations.
            </p>
           
            <h1 class="white--text">🎓 Studies 🎓</h1>


            <h3 class="grey--text">• <a href="https://www.uni-weimar.de/de/universitaet/start/">Bauhaus Universität</a> • Weimar • Germany • Winter Semester 2023 •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
            <p class="grey--text">
                Erasmus of 6 months in Weimar to study art, animation and sound. <br/>
            </p>
            <h4 class="grey--text">Extra activities </h4>
            <p class="grey--text"> Association - Gamejam 803Z 2024 (24H of the videogame) <br/>
            </p>


            <h3 class="grey--text">• <a href="https://www.ingenieur-imac.fr/">IMAC</a> • Champs-sur-Marne • France • 2020-2024 •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
            <p class="grey--text">
                3 years of science, sound, short movies, web, games programming, <br/> 
                design and communication. 1 year of break.
            </p>
            <h4 class="grey--text">Extra activities </h4>
            <p class="grey--text"> Association - Organization of parties and events like the 48H of short movies and the <br/>
                23H of the comic / animation of weekly drawing sessions. <br/>
                Personal - Maths tutoring for a student in highschool. 
            </p>


            <h3 class="grey--text">• INSA Lyon • Villeurbanne • France • 2018-2020 •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
            <p class="grey--text">
                2 years of traditional engineer formation, with an art option during <br/> 
                the second year (photography, painting, sculpture). 
            </p>
            <h4 class="grey--text">Extra activities</h4>
            <p class="grey--text"> Organization of the BAL 2020 and artistic projects like frescoes and theater decors.
            </p>


            <h3 class="grey--text">• Notre Dame de SION • Evry • France • 2015-2018 •</h3>
            <hr style="background-color: grey;height:0.3vh;width:70%;border-color:grey">
            <p class="grey--text">
                3 years of high school with an option programming during the last year. <br/>
            </p>
            <h4 class="grey--text">Association Commitment </h4>
            <p class="grey--text"> Tutoring for younger students / Dance class.
            </p>
            
          </v-col>
          <v-col cols="4">
            <h5 class="red--text text--darken-4 mt-10">Competences</h5>
            <h3 class="grey--text">• Languages •</h3>
            <p class="grey--text"> 
              French (first language) <br/> 
              English (fluent)<br/>
              German (B1) <br/>
            </p>
            <h3 class="grey--text">• Soft skills •</h3>
            <p class="grey--text"> 
              Project Gestion <br/> 
              Team Work<br/>
            </p>
            <h3 class="grey--text">• Programmation •</h3>
            <p class="grey--text"> 
              C++, C, Java, Python <br/> 
              HTML, CSS, javascript<br/> 
            </p>
            <h3 class="grey--text">• Art skills •</h3>
            <p class="grey--text"> 
              Adobe (photoshop, premiere pro, after effects)<br/> 
              Painting, drawing (procreate, acrylic, oil, pencils)<br/> 
              Logo (illustrator, procreate, figma)<br/> 
              3D (blender)<br/> 
              Animation (procreate, procreate Dreams, <br/> TV paint, Blender)
              <br/><br/><br/><br/><br/>
            </p>
            
            <v-col cols="12" class="padd topInverse">
              <div class="secondchild1" id="portfolio">
                <v-row>
                    <h1 class="white--text ml-8 mr-8 ">
                      You can download my resume 
                    </h1>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                    <v-btn tile color="#A7121D" dark class="mt-8" download href="CV2023.pdf"
                    >
                      Here
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-row>
              </div>
          </v-col>
        </v-col>
      
        
          <!-- <v-col cols="12" class="padd">
            <div id="project">
              <v-row>

                <v-col cols="12" class="mt-10">
                
                  <div class="child1">
                    <h1 class="red--text text--darken-4 mt-4 number">12</h1>
                    <h3 class="white--text mt-4">Years Experience</h3>
                  </div>
                
                  <div class="child2 mRight">
                    <v-row>
                      <v-col cols="12" class="childcol">
                        <div class="child2 mButton padding bgColor1">
                          <h1 class="red--text text--darken-4">60+</h1>
                          <p class="grey--text">Clients</p>
                        </div>
                      </v-col>
                
                      <v-col cols="12" class="childcol">
                        <div class="child2 padding bgColor1">
                          <h1 class="red--text text--darken-4">122+</h1>
                          <p class="grey--text">Completed Projects</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                
                  <div class="child2">
                    <v-row>
                      <v-col cols="12" class="childcol">
                        <div class="child2 mButton padding bgColor1">
                          <h1 class="red--text text--darken-4">08</h1>
                          <p class="grey--text">Years Experience</p>
                        </div>
                      </v-col>
                
                      <v-col cols="12" class="childcol">
                        <div class="child2 padding bgColor1">
                          <h1 class="red--text text--darken-4">10</h1>
                          <p class="grey--text">Achievements</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
           -->
          
        </v-row>
      </v-container>
    </v-app>
  </template>
  
  <script>
  
export default {
    name: "CursusPage",
    props: {
      
    },
    data: () => ({
      projectNb: {type: Int16Array, required: true},
    }),
    components: {
    },
    methods: {
      updateDisplay(display) {
        this.$emit('updateDisplay', display);
      },
    },
  };
  </script>
  <style scoped>
  .container-cursus {
    padding: 5vh;
  }
  .top {
    margin-top: 180px;
  }
  .topInverse {
    margin-top: -250px;
  }
  .topTolbar {
    margin-top: 100px;
    text-align: center;
  }
  .secondchild1 {
    display: inline-block;
    background-color: #1e1e1e;
    padding: 2rem 1rem;
    vertical-align: middle;
    text-align: center;
    margin-top: 150px;
    font-size: medium;
  }
 
  .col-12.padd {
    padding: 12px 0 !important;
  }
 
  @media only screen and (max-width: 820px) {
    /* Styles pour les écrans de 768 pixels de largeur ou moins */
    .container-cursus {
      display : row;
    }
    .secondchild1 {
      display: none;
    }
  }
  </style>