<template>
    <v-footer dark padless>
      <v-card
        flat
        tile
        class="black grey--text py-5 px-5 text-center"
        width="100%"
      >
        <v-row>
          <v-col id="contact" cols="12" sm="4">
            <v-btn icon href="https://www.instagram.com/togramf/">
                  <v-icon color="#A7121D" > mdi-instagram</v-icon>
                </v-btn>
              <v-btn icon href="https://www.linkedin.com/in/togramf">
                <v-icon color="#A7121D"> mdi-linkedin</v-icon>
              </v-btn>
              <v-btn icon href="https://www.youtube.com/channel/UCNdRTPiWdjItitEeieDZq-Q">
                <v-icon color="#A7121D"> mdi-youtube</v-icon>
              </v-btn>
              <v-btn icon @click="ouvrirClientMessagerie()">
                <v-icon color="#A7121D"> mdi-email</v-icon>
              </v-btn>
          </v-col>
          <v-spacer>
            
          </v-spacer>
          <v-col cols="12" sm="4">
            <v-card-text class="pt-2">
              From a template by AAE IdeaPro
              <v-btn class="mx-1 grey--text" icon>
                <v-icon color="#A7121D">mdi-heart</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text class="pt-1 mt-1 ml-1" >
                Icons by icons8
              </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
  </template>
  
  <script>
  export default {
    methods: {
      scroll(refName) {
        const element = document.getElementById(refName);
        element.scrollIntoView({ behavior: "smooth" });
      },
      ouvrirClientMessagerie() {
        const adresseEmail = 'togramf2@gmail.com';

        // Utiliser le protocole 'mailto:' pour ouvrir le client de messagerie par défaut
        const lienMailto = `mailto:${adresseEmail}`;

        // Ouvrir le lien dans une nouvelle fenêtre ou un nouvel onglet
        window.open(lienMailto, '_blank');
      }
    },
  };
  </script>
  
  <style>
  </style>
  